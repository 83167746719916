const awardUrls = [
  'https://clutch.co/profile/deviark',
  'https://clutch.co/profile/deviark',
  'https://clutch.co/profile/deviark',
  'https://clutch.co/profile/deviark',
  'https://clutch.co/profile/deviark',
  'https://clutch.co/profile/deviark',
  'https://itcluster.lviv.ua/en/deviark-joins-lviv-it-cluster',
];

export default function ClutchAward({ index }) {
  const normalizedIndex = index + 1;

  return (
    <a
      href={awardUrls[index]}
      target="_blank"
      rel="noopener noreferrer"
      className="clutch-container__images-link"
    >
      <img
        src={`/img/footer/clutch-${normalizedIndex}.png`}
        alt={`Clutch Award ${normalizedIndex}`}
        className="link-image"
      />
    </a>
  );
}
