import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import InTouch from './InTouch';
import Navigation from './Navigation';
import ContactInfo from './ContactInfo';
import ClutchRating from './Clutch/ClutchRating';
import ClutchAward from './Clutch/ClutchAward';
import { isEnglish } from '../../utils/helpers';

const awardIndexes = [0, 1, 2, 3, 4, 5, 6];

const Footer = () => {
  const {
    i18n: { language },
  } = useTranslation();

  const { pathname } = useLocation();
  const isCurrentPageNotContact = pathname !== '/contact';

  return (
    <footer className="footer">
      <div className="container">
        <div
          className={classNames('row footer__top p-0 justify-content-between', {
            'clutch-container': !isEnglish(language),
          })}
        >
          {isCurrentPageNotContact ? (
            <div className="col-12 col-md-6 col-lg-6 p-0">
              <InTouch />
            </div>
          ) : null}

          {isEnglish(language) && (
            <div
              className={classNames('col-12', 'p-0', {
                'col-md-6': isCurrentPageNotContact,
                'col-lg-6': isCurrentPageNotContact,
              })}
            >
              <Navigation />
            </div>
          )}
          {!isEnglish(language) && <ClutchComponent />}
        </div>

        <div className="row clutch-container">
          <div className="col-12 col-md-6 col-lg-6 p-0">
            <ClutchRating />
          </div>

          {isEnglish(language) && <ClutchComponent />}
        </div>

        <div className="row footer__bottom">
          <ContactInfo />
        </div>
      </div>
    </footer>
  );
};

export default Footer;

function ClutchComponent() {
  return (

<div className='d-flex col-11 col-md-5 col-lg-5 p-0 ml-5'>
  <div className="col-9 col-md-9 col-lg-9 p-0">
    <div className="row clutch-container__images">
      {awardIndexes.slice(0, 6).map((index) => (
        <div className="col-4 p-0 mt-3" key={index}>
          <ClutchAward index={index} />
        </div>
      ))}
    </div>
  </div>
  <div className="d-flex col-3 col-md-3 col-lg-3 p-0">
    <div className="clutch-container__images d-flex align-items-center mt-3">
      {awardIndexes.slice(6).map((index) => (
        <ClutchAward index={index} key={index} />
      ))}
    </div>
  </div>
</div>


  );
}
